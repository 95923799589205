import { useMutation, useQuery, useQueryClient } from "react-query";
import { useAuth, useClient } from "../context/auth-context";
import { useTenantContext } from "../context/tenant-context";
import { useToast } from "../context/toast-context";
import { AuthFlow } from "../domain/auth";
import { FeatureConfiguration, FeatureConfigurationPatchPayload } from "../domain/features";
import { client as pureClient } from "../utils/api-client";

export const defaultConfiguration: FeatureConfiguration = {
  activeServices: {
    paths: true,
    worlds: false
  },
  canViewFollowers: false,
  reactions: [],
  interests: [],
  kudosCategories: [],
  enableMultipleOrganisations: false,
  surveyMoodQuestionOptions: [],
  reelConfiguration: {
    requireReviewBeforePublishing: false
  },
  appConfiguration: {
    isSocialEnabled: false,
    isKudosEnabled: false,
    isChatEnabled: false,
    isSurveysEnabled: false,
    isCoursesEnabled: false,
    isCompanyGoalsEnabled: false,
    isCompanyValuesEnabled: false,
    isContentZoneEnabled: false,
    isMHFAEnabled: false,
    isGroupsEnabled: false,
    isUserPostAndCommentEnabled: false,
    maxNumberOfMediaInComments: 0,
    maxPostLength: 0
  },
  allowPostToAncestorAndDescendantOrganisations: false,
  allowUserPostToDepartment: false,
  includeStructureOptionsInGroupAudienceList: false,
  disableSendingInvitations: false,
  hashTagRegex: { pattern: "", flags: "" },
  urlRegex: { pattern: "", flags: "" },
  flags: {
    isSocialEnabled: false,
    isKudosEnabled: false,
    isContentZoneEnabled: false,
    isBadgesEnabled: false,
    isWebGameComponentEnabled: false
  },
  authConfig: {
    allowedPhoneCountryCodes: [],
    authFlow: AuthFlow.passwordless
  },
  emailRegex: {
    flags: "",
    pattern: ""
  },
  defaultUserRole: "user",
  apiVersion: 1,
  intelligentSearch: {
    defaultScore: 0.2,
    maxResults: 10,
    generateAnswer: false,
    indexOnlyVerified: false,
    translateQuestionsFromOtherLanguages: false,
    indexedContentLanguage: "English",
    answerContext: {
      maxNumberOfWords: 2000,
      maxResultsToUse: 3,
      maxFieldsPerResult: 3,
      providers: []
    },
    modelSettings: {
      embeddingModel: "",
      useChatCompletion: true,
      completionModel: "",
      chatCompletionModel: "",
      temperature: 0.1,
      promptFormat: "Context: {{context}}\n===\nQ: {{query}}\nA:",
      chatPromptPrefix: "Using the following context, answer the question\n{{prompt}}"
    }
  },
  maxNumberOfIntegrations: 0,
  compliance: {
    successMinValue: 0.5,
    warnMinValue: 0.2,
    soonThresholdDays: 7
  }
};

function useFeaturesQuery() {
  const client = useClient<FeatureConfiguration>();
  const { activeTenant } = useTenantContext();
  const { data: featuresConfig, isLoading } = useQuery<FeatureConfiguration, Error>({
    enabled: !!activeTenant,
    queryKey: `tenant-${activeTenant}-features`,
    queryFn: () =>
      client(`tenants/${activeTenant}/features`)
        .then(data => data)
        .catch(error => error),
    onError: _ => {
      return defaultConfiguration;
    }
  });
  return { featuresConfig: featuresConfig ?? defaultConfiguration, isLoading };
}

function useFeatureConfigurationForTenant(tenantId?: string) {
  const client = useClient<FeatureConfiguration>();
  const { data: featuresConfig, isLoading } = useQuery<FeatureConfiguration, Error>({
    enabled: !!tenantId,
    queryKey: ["featureconfiguration", tenantId],
    queryFn: () =>
      client(`features?level=tenant&tenantId=${tenantId}`)
        .then(data => data)
        .catch(error => error),
    onError: _ => {
      return defaultConfiguration;
    }
  });
  return { featuresConfig: featuresConfig ?? defaultConfiguration, isLoading };
}

function useFeatureConfigurationForOrganisation(tenantId?: string, organisationId?: string) {
  const client = useClient<FeatureConfiguration>();
  const { data: featuresConfig, isLoading } = useQuery<FeatureConfiguration, Error>({
    enabled: !!tenantId && !!organisationId,
    queryKey: ["featureconfiguration-org", tenantId, organisationId],
    queryFn: () =>
      client(`features?level=organisation&tenantId=${tenantId}&organisationId=${organisationId}`)
        .then(data => data)
        .catch(error => error),
    onError: _ => {
      return defaultConfiguration;
    }
  });
  return { featuresConfig: featuresConfig ?? defaultConfiguration, isLoading };
}

function useDefaultFeatureConfiguration() {
  const client = useClient<FeatureConfiguration>();
  const { data: featuresConfig, isLoading } = useQuery<FeatureConfiguration, Error>({
    queryKey: ["featureconfiguration", "__DEFAULT__"],
    queryFn: () =>
      client(`features?level=global`)
        .then(data => data)
        .catch(error => error),
    onError: _ => {
      return defaultConfiguration;
    }
  });
  return { featuresConfig: featuresConfig ?? defaultConfiguration, isLoading };
}

interface AddFeatureOverrideCommand {
  level: "tenant" | "global" | "organisation";
  appliesTo?: string;
  settings: Partial<FeatureConfiguration>;
  onSuccess?: () => void;
}

function useApplyFeatureOverride() {
  const client = useClient();
  const queryClient = useQueryClient();
  const { showSnackBar } = useToast();

  const mutation = useMutation<unknown, Error, AddFeatureOverrideCommand>(
    command => {
      const { onSuccess, ...data } = command;
      return client(`features`, {
        method: "PATCH",
        data
      });
    },
    {
      onSuccess: (_, command) => {
        queryClient.invalidateQueries(["featureconfiguration"]);
        if (command.appliesTo) {
          queryClient.invalidateQueries(`tenant-${command.appliesTo}-features`);
        }

        command.onSuccess && command.onSuccess();

        showSnackBar && showSnackBar("Changes applied");
      },
      onError: () => {
        showSnackBar && showSnackBar("Error applying changes", undefined, "error");
      }
    }
  );

  return mutation;
}

interface ResetTenantToDefaultConfigurationCommand {
  tenantId: string;
  onSuccess?: () => void;
}

function useResetTenantConfigurationToDefault() {
  const client = useClient();
  const queryClient = useQueryClient();
  const { showSnackBar } = useToast();

  const mutation = useMutation<unknown, Error, ResetTenantToDefaultConfigurationCommand>(
    command => {
      return client(`features?level=tenant&appliesTo=${command.tenantId}`, {
        method: "DELETE"
      });
    },
    {
      onSuccess: (_, command) => {
        queryClient.invalidateQueries(["featureconfiguration"]);
        if (command.tenantId) {
          queryClient.invalidateQueries(`tenant-${command.tenantId}-features`);
        }

        command.onSuccess && command.onSuccess();

        showSnackBar && showSnackBar("Changes applied");
      },
      onError: () => {
        showSnackBar && showSnackBar("Error applying changes", undefined, "error");
      }
    }
  );

  return mutation;
}

function useTenantFeaturesWithoutAppClient(token?: string) {
  const { activeTenant } = useTenantContext();
  const { features: publicFeatures } = usePublicFeatures();

  const { data, isLoading } = useQuery<FeatureConfiguration, Error>({
    enabled: !!activeTenant && !!token,
    queryKey: [`auth-configs-pure`, activeTenant],
    queryFn: () =>
      pureClient<FeatureConfiguration>(`tenants/${activeTenant}/features`, { token })
        .then(data => data)
        .catch(error => error),
    onError: _ => {
      return publicFeatures;
    }
  });

  return { features: data ?? publicFeatures, isLoading };
}

const useApiVersion = () => {
  const { identity } = useAuth();
  const { features } = useTenantFeaturesWithoutAppClient(identity?.token);
  return { apiVersion: features.apiVersion };
};

const usePatchFeatures = () => {
  const client = useClient<FeatureConfiguration>();
  const queryClient = useQueryClient();
  const { showSnackBar } = useToast();
  const { activeTenant } = useTenantContext();

  const mutation = useMutation<FeatureConfiguration, Error, FeatureConfigurationPatchPayload>(
    updates =>
      client(`tenants/${activeTenant}/features`, {
        method: "PATCH",
        data: updates
      }),
    {
      onSuccess: (_, updates) => {
        queryClient.setQueryData(`tenant-${activeTenant}-features`, (old: FeatureConfiguration | undefined) => {
          if (old) {
            return { ...old, appConfiguration: { ...old.appConfiguration, ...updates } };
          } else {
            return defaultConfiguration;
          }
        });

        showSnackBar && showSnackBar("featureSwitched");
      },
      onError: _ => {
        showSnackBar && showSnackBar("featureSwitchedError");
      }
    }
  );

  return mutation;
};

function usePublicFeatures() {
  const { data, isLoading } = useQuery<FeatureConfiguration, Error>({
    queryKey: [`auth-config`],
    queryFn: () =>
      pureClient<FeatureConfiguration>(`features/public`)
        .then(data => data)
        .catch(error => error),
    onError: _ => {
      return defaultConfiguration;
    }
  });

  return { features: data ?? defaultConfiguration, isLoading };
}

export {
  useApiVersion,
  useApplyFeatureOverride,
  useDefaultFeatureConfiguration,
  useFeatureConfigurationForOrganisation,
  useFeatureConfigurationForTenant,
  useFeaturesQuery,
  usePatchFeatures,
  usePublicFeatures,
  useResetTenantConfigurationToDefault,
  useTenantFeaturesWithoutAppClient
};
